/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import algoliasearch from "algoliasearch/lite";
import FilterIcon from "../components/filter-svg";
import chevronIcon from "../img/chevron-right.svg";
import SEO from "../components/seo";

import {
  InstantSearch,
  connectSearchBox,
  connectStateResults,
  connectPagination,
  Highlight,
  Hits,
  Snippet,
  RefinementList,
} from "react-instantsearch-dom";
import Layout from "../components/layout";
import React, { Component } from "react";

const VirtualSearchBox = connectSearchBox(() => <span />);
const appId = process.env.GATSBY_ALGOLIA_APP_ID;
const searchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY;
const searchClient = algoliasearch(appId, searchKey);
const indices = [{ name: `Posts`, title: `Posts` }];

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.handleTags = this.handleTags.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.state = {
      tagId: "all",
      toggle: true,
      page: 1,
      searchState: {
        query: "",
      },
    };
  }

  componentDidMount() {
    // Get "term" query string parameter value.
    if (typeof window !== "undefined") {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let searchTerm = params.get("q");
      this.setState(state => ({
        searchState: {
          ...state.searchState,
          query: searchTerm,
        },
      }));
    }
  }

  handleTags(e, id) {
    e.preventDefault();
    id = id.toLowerCase();
    this.setState({
      tagId: id,
    });
  }

  handleToggle(e) {
    e.preventDefault();
    this.setState(state => ({
      toggle: !state.toggle,
    }));
  }

  handleReset(e, type) {
    e.preventDefault();

    if (type === "tags") {
      this.setState({
        tagId: "all",
      });
    }
  }

  render() {
    const SearchBox = ({ currentRefinement, refine }) => (
      <input
        type="search"
        defaultValue={this.state.searchState.query}
        style={{
          visibility: "hidden",
          height: "0",
          width: "0",
          fontSize: "0",
          padding: "0",
          border: "none",
        }}
      />
    );

    const CustomSearchBox = connectSearchBox(SearchBox);
    const PageHit = ({ hit }) => {
      return (
        <div className="single-result" key={hit.id}>
          <Link className="add-underline" to={hit.path}>
            <h4 className="subtitle-one result-title">
              <Highlight attribute="title" hit={hit} tagName="mark" />
            </h4>
          </Link>
          {hit.breadcrumbs && hit.breadcrumbs[0] && (
            <div className="breadcrumb-container">
              {hit.breadcrumbs.map((breadcrumb, i) => {
                return (
                  <>
                    <Link
                      key={i}
                      to={breadcrumb.path}
                      className="breadcrumb-title caption add-underline"
                    >
                      {breadcrumb.title}
                    </Link>
                    {i < hit.breadcrumbs.length && (
                      <img src={chevronIcon} alt="" />
                    )}
                  </>
                );
              })}
            </div>
          )}
          <Snippet attribute="frontmatter" hit={hit} tagName="mark" />
        </div>
      );
    };

    const Pagination = ({ currentRefinement, nbPages, refine }) => {
      const currentPage = currentRefinement;
      return (
        <ul className="pagination-container">
          {/* Left navigation arrow */}
          <li
            className={`paginate-button${
              currentPage === 1 ? " -disabled" : ""
            }`}
          >
            <a
              className="paginate-link"
              href="#gatsby-focus-wrapper"
              onClick={e => {
                refine(1);
              }}
            >
              &lt;&lt;
            </a>
          </li>
          <li
            className={`paginate-button${
              currentPage === 1 ? " -disabled" : ""
            }`}
          >
            <a
              className="paginate-link"
              href="#"
              onClick={e => {
                refine(currentPage - 1);
              }}
            >
              &lt;
            </a>
          </li>
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              page >= currentRefinement - 2 &&
              page <= currentRefinement + 2 && (
                <li
                  key={index}
                  className={`paginate-button${
                    currentRefinement === page ? " -active" : ""
                  }`}
                >
                  <a
                    className="paginate-link"
                    href="#gatsby-focus-wrapper"
                    onClick={e => {
                      refine(page);
                    }}
                  >
                    {page}
                  </a>
                </li>
              )
            );
          })}

          {/*  Right Navigation arrow */}
          <li
            className={`paginate-button${
              currentPage === nbPages ? " -disabled" : ""
            }`}
          >
            <a
              className="paginate-link"
              href="#gatsby-focus-wrapper"
              onClick={e => {
                refine(currentPage + 1);
              }}
            >
              &gt;
            </a>
          </li>
          <li
            className={`paginate-button${
              currentPage === nbPages ? " -disabled" : ""
            }`}
          >
            <a
              className="paginate-link"
              href="#gatsby-focus-wrapper"
              onClick={e => {
                refine(nbPages);
              }}
            >
              &gt;&gt;
            </a>
          </li>
        </ul>
      );
    };
    const CustomPagination = connectPagination(Pagination);

    const CustomResults = connectStateResults(
      ({ searchState, searchResults }) => {
        return (
          <div className="results-wrapper">
            {searchResults && searchResults.nbHits ? (
              <div>
                <Hits hitComponent={PageHit} />
              </div>
            ) : (
              <div className="no-results">
                No results found matching &quot;
                <span className="query">{searchState.query}</span>
                &quot;
              </div>
            )}
            <CustomPagination />
          </div>
        );
      }
    );

    const tags = ["Page", "News", "Events"];
    return (
      <Layout>
        {/* Dont index search page */}
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <SEO title="Search" />
        <section className="search-results">
          <div
            className={this.state.toggle ? "filter-bar" : "filter-bar -active"}
          >
            <a
              href="#"
              onClick={e => this.handleToggle(e)}
              className="filters-ui"
            >
              <FilterIcon />
              <p className="filter-heading subtitle-two">Filters</p>
            </a>
            {/* TAG FILTER */}
            <div className="filter-category-container">
              {this.state.tagId === "all" && (
                <p className="filter-heading subtitle-two">All Results</p>
              )}

              {this.state.tagId !== "all" && (
                <a
                  className="filter-heading subtitle-two filter-close add-underline"
                  href="#"
                  onClick={e => this.handleReset(e, "tags")}
                >
                  {this.state.tagId[0].toUpperCase() +
                    this.state.tagId.slice(1)}
                  <span>{"\u2715"}</span>
                </a>
              )}

              <ul
                className={
                  this.state.toggle
                    ? "filter-category-list"
                    : "filter-category-list -active"
                }
              >
                {tags.map((tag, i) => {
                  if (tag === this.state.tagId) {
                    return "";
                  } else {
                    return (
                      <li className="filter-item" key={i}>
                        <a
                          href="#"
                          className="filter-button add-underline"
                          onClick={e => this.handleTags(e, tag)}
                          key={i}
                        >
                          {tag}
                        </a>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          <div className="post-list archives-list">
            <InstantSearch
              searchClient={searchClient}
              indexName={indices[0].name}
            >
              <CustomSearchBox
                defaultRefinement={this.state.searchState.query}
              />
              {this.state.searchState.query && <CustomResults />}
              <VirtualSearchBox />
              <RefinementList
                attribute="type"
                defaultRefinement={[
                  this.state.tagId === "all" ? "" : this.state.tagId,
                ]}
              />
            </InstantSearch>
          </div>
        </section>
      </Layout>
    );
  }
}

export default SearchPage;
